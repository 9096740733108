
import Button from '../elements/Button.vue';
import getLastNews from '../../queries/getLastNews';

export default {
  components: { Button },
  props: {
    data: {
      type: Object,
      default: null
    },
    _updatedAt: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      lastNews: null
    };
  },
  async fetch() {
    const query = getLastNews();
    this.lastNews = await this.$sanity.fetch(query);
  }
};
